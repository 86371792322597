export const environment = {
   production: true,
   hmr: false,
   appMetadata: {
      appDomain: {
         title: 'dapfood.net',
         owner: 'dapfood.net',
         logo: 'assets/images/logo-mypha.png',
      }
   },
   apiDomain: {
      gateway: 'https://faapi.friends.vn',
      authenticationEndpoint: 'https://faaccount.friends.vn',
      authorizationEndpoint: 'https://faapi.friends.vn/authorization.api',
      fileEndpoint: 'https://faapi.friends.vn/file.api',
      notificationEndpoint: 'https://fanotification.friends.vn',
      dapFoodEndPoint: 'https://faapi.friends.vn/dapfood.api',
      linkEndPoint: 'https://faapi.friends.vn/weblink',
      dapEInvoiceEndPoint: 'https://faapi.friends.vn/dapeinvoice',
      logEndPoint: 'https://logs.mypha.vn',
      documentServer: 'https://documentserver.friends.vn',
   },
   clientDomain: {
      appDomain: 'https://fa.friends.vn',
      qthtDomain: 'https://adminfa.friends.vn',
      idPhanhe: 2,
   },
   authenticationSettings: {
      clientId: 'dapfood',
      issuer: 'https://faaccount.friends.vn'
   },
   systemLogSetting: {
      enabled: false
   },
   signalr: {
      clientKey: 'dapfood',
      endpoint: '',
      linkDownloadClientApp: ''
   },
   signalrConfig: {
      hub: {
         notification: 'NotificationHub'
      },
      action: {
         notificationCreated: '',
         viewUpdated: ''
      }
   },
   firebaseConfig: {
      apiKey: 'AIzaSyAT7El0qT5wMtbjX91UpPIZrtyVd6hnwAo',
      authDomain: 'dapfood-90ada.firebaseapp.com',
      databaseURL: 'https://dapfood-90ada.firebaseio.com',
      projectId: 'dapfood-90ada',
      storageBucket: 'dapfood-90ada.appspot.com',
      messagingSenderId: '729900142463',
      appId: '1:729900142463:web:bb8698bb2011fad3fb944a',
      measurementId: 'G-1M9VDRK7ZM'
   }
}