import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserAddressService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/UserAddress`);
    }
    GetsByUserId(UserId) {
        const queryString = `${this.serviceUri}?UserId=${UserId}`;
        return this.defaultGet(queryString);
    }
    GetByUserIdEditOrder(userId) {
        const queryString = `${this.serviceUri}/GetByUserIdEditOrder?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetDefault(userId) {
        const queryString = `${this.serviceUri}/GetDefault?userId=${userId}`;
        return this.defaultGet(queryString);
    }
    GetIsSyn() {
        const queryString = `${this.serviceUri}/GetIsSyn?limit=10`;
        return this.defaultGet(queryString);
    }
}
UserAddressService.ngInjectableDef = i0.defineInjectable({ factory: function UserAddressService_Factory() { return new UserAddressService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: UserAddressService, providedIn: "root" });
