
import {
  Component,
  OnInit,
  forwardRef,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  Injector
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ConfirmationService, DialogService, DynamicDialogRef, FileUpload, MessageService } from 'primeng/primeng';
import { ConfigurationService } from '../../../services/configuration.service';
import { environment } from '../../../../../environments/environment';
import { FileViewerComponent } from '../../file-upload/file-viewer/file-viewer.component';
import { VsFileUploadService } from '../../file-upload/services/file-upload.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';

@Component({
  selector: 'app-vs-file-upload',
  templateUrl: './vs-file-upload.component.html',
  styleUrls: ['./vs-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsFileUploadComponent),
      multi: true
    }
  ]
})
export class VsFileUploadComponent extends SecondPageEditBase implements OnInit, ControlValueAccessor {
  disabled = false;
  apiUrl = '';
  uploading = false;
  maxFileSize = 0;
  listUploaded: any[];
  progressValue = 0;
  ref: DynamicDialogRef;

  @ViewChild(FileUpload) fileControl: FileUpload;

  @Input() chooseLabel = 'Chọn file';
  @Input() multiple = false;
  @Input() styleButton = false;
  @Input() defaultImage = false;
  @Input() showIconOnly = false;
  @Input() icon = 'fa fa-upload';
  @Input() viewOnly = false;
  @Input() fileType = '';    // '' , image/* , video/* , 
  @Output() onUploaded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(FileViewerComponent) _FileViewerComponent: FileViewerComponent;

  constructor(
    protected _injector: Injector,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private _fileUploadService: VsFileUploadService,
    private _configurationService: ConfigurationService,
    public dialogService: DialogService,
  ) {
    super(null, _injector);
    this.apiUrl = `${environment.apiDomain.fileEndpoint}/Files/upload`;  // _moduleConfigService.getConfig().ApiFileUpload;
    this.maxFileSize = this._configurationService.maxFileSize;
  }

  ngOnInit() {
  }

  onChangeControl = (obj: any) => { };
  onTouched = () => { };

  writeValue(obj: any): void {
    this.listUploaded = [];
    if (obj) {
      this.listUploaded = obj.split(",");

      this._fileUploadService.GetsByIds(obj).then(rs => {
        if (rs.status) {
          this.listUploaded = rs.data;
        }
      }).catch(err => {
        console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeControl = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onUploadEvent(event: any) {
    try {
      const files = event.files;
      if (files.length > 0) {
        if (event.originalEvent.body && event.originalEvent.body.status) {

          // Nếu là single thì clear list đi để lưu ảnh mới, nếu là multi thì push thêm ảnh
          if (this.multiple == false)
            this.listUploaded = [];

          event.originalEvent.body.data.forEach(element => {
            this.listUploaded.push(element);  // list object file    
          });

          let files = this.listUploaded.map(x => x.id).join(",");
          this.onChangeControl(files);
          this.onUploaded.next(files);

          this.progressValue = 100;
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.uploading = false;
  }
  onProgress(event) {
    // if (event.originalEvent.loaded !== 0) {
    //   this.progressValue = (event.originalEvent.loaded / event.originalEvent.total) * 100;
    // }
  }

  deleteFile(id) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn xóa file này không ?',
      accept: () => {
        const fileToRemove = this.listUploaded.findIndex(x => x.id === id);
        this.listUploaded.splice(fileToRemove, 1);
        let files = this.listUploaded.map(x => x.id).join(",");
        this.onChangeControl(files);
        this.onUploaded.next(files);

        this.writeValue(files);
      }
    });
  }

  onBeforeSend(ev) {
    console.log(ev);
  }

  onError(ev) {
    console.log(ev);
    this.uploading = false;
  }

  onSelect(ev) {
    console.log(ev);
    // this.messageService.add({ severity: 'error', summary: 'Lỗi upload file', detail: 'Vui lòng kiểm tra lại định dạng file', life: 10000 });
  }

  selectFileUpload() {
    this.fileControl.basicFileInput.nativeElement.click();
  }

  onBeforeUpload() {
    this.progressValue = 1;
    this.uploading = true;
  }

  viewFile(item) {
    // Nếu file là hình ảnh hoặc vido thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
    if (item.type == 1 || item.type == 3)
      this._FileViewerComponent.showPopupObject(item, this.listUploaded);
    else {
      this.ref = this.dialogService.open(VsFileViewerComponent, {
        data: item,
        showHeader: true,
        header: item.fileName,
        width: '100%',
        height: 'calc(100vh - 0px)',
        styleClass: "vs-modal vs-file-viewer",
        contentStyle: { 'overflow': 'auto' }, //'max-height': 'calc(100vh - 180px);', 
        baseZIndex: 1010,
        closeOnEscape: true
      });

    }
  }

  downloadFileAll() {
    this.listUploaded.forEach(element => {
      this.downloadFile(element);
    });
  }

}
