/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-viewer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i4 from "primeng/components/dialog/dialog";
import * as i5 from "./file-viewer.component";
var styles_FileViewerComponent = [i0.styles];
var RenderType_FileViewerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FileViewerComponent, data: {} });
export { RenderType_FileViewerComponent as RenderType_FileViewerComponent };
function View_FileViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ui-state-default ui-lightbox-nav-left ui-corner-right"], ["style", "z-index: 1003;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "ui-lightbox-nav-icon pi pi-chevron-left"]], null, null, null, null, null))], null, null); }
function View_FileViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "video", [["autoplay", ""], ["controls", ""], ["name", "media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [], [[8, "src", 4], [8, "type", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.file == null) ? null : _co.file.url); var currVal_1 = i1.ɵinlineInterpolate(1, "video/", _co.file.extention.replace(".", ""), ""); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_FileViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.file == null) ? null : _co.file.url); _ck(_v, 1, 0, currVal_0); }); }
function View_FileViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ui-state-default ui-lightbox-nav-right ui-corner-left"], ["style", "z-index: 1003;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "ui-lightbox-nav-icon pi pi-chevron-right"]], null, null, null, null, null))], null, null); }
function View_FileViewerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "width: 60px;height: 100%;"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getCurrent(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urlImageOriginal(_v.parent.context.$implicit.path); _ck(_v, 0, 0, currVal_0); }); }
function View_FileViewerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "img"; var currVal_1 = _ck(_v, 2, 0, (_v.context.index === _co.selectedIndex)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.type == 1); _ck(_v, 4, 0, currVal_2); }, null); }
function View_FileViewerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list_img"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listFile; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileViewerComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "width: 60px;height: 100%;"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getCurrent(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.url; _ck(_v, 0, 0, currVal_0); }); }
function View_FileViewerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "img"; var currVal_1 = _ck(_v, 2, 0, (_v.context.index === _co.selectedIndex)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.type == 1); _ck(_v, 4, 0, currVal_2); }, null); }
function View_FileViewerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list_img"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listFile; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FileViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "p-dialog", [["appendTo", "body"], ["positionBottom", "0"], ["positionTop", "0"], ["styleClass", "vs-modal force-full-width file-viewer"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showFileDialog = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Dialog_0, i3.RenderType_Dialog)), i1.ɵdid(1, 180224, [["dialog", 4]], 2, i4.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], positionTop: [2, "positionTop"], contentStyle: [3, "contentStyle"], modal: [4, "modal"], appendTo: [5, "appendTo"], style: [6, "style"], styleClass: [7, "styleClass"], showHeader: [8, "showHeader"], maximizable: [9, "maximizable"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(4, { "height": 0 }), i1.ɵpod(5, { "bottom": 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "fileinfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_4)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "btnClose"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showFileDialog = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "span", [["class", "pi pi-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_FileViewerComponent_5)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_FileViewerComponent_8)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showFileDialog; var currVal_1 = ((_co.file == null) ? null : _co.file.fileName); var currVal_2 = "0"; var currVal_3 = _ck(_v, 4, 0, "100vh"); var currVal_4 = true; var currVal_5 = "body"; var currVal_6 = _ck(_v, 5, 0, "0"); var currVal_7 = "vs-modal force-full-width file-viewer"; var currVal_8 = false; var currVal_9 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = (((_co.listFile == null) ? null : _co.listFile.length) > 0); _ck(_v, 10, 0, currVal_11); var currVal_12 = (_co.file.type == 3); _ck(_v, 12, 0, currVal_12); var currVal_13 = (_co.file.type == 1); _ck(_v, 14, 0, currVal_13); var currVal_14 = (((_co.listFile == null) ? null : _co.listFile.length) > 0); _ck(_v, 16, 0, currVal_14); var currVal_15 = _co.file.path; _ck(_v, 20, 0, currVal_15); var currVal_16 = !_co.file.path; _ck(_v, 22, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_10 = ((_co.file == null) ? null : _co.file.fileName); _ck(_v, 8, 0, currVal_10); }); }
export function View_FileViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-viewer", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FileViewerComponent_0, RenderType_FileViewerComponent)), i1.ɵdid(1, 245760, null, 0, i5.FileViewerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileViewerComponentNgFactory = i1.ɵccf("file-viewer", i5.FileViewerComponent, View_FileViewerComponent_Host_0, {}, {}, []);
export { FileViewerComponentNgFactory as FileViewerComponentNgFactory };
