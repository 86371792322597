import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../dapfood/services/districts.service';
import { CustomerTypesService } from '../../../dapfood/services/customerTypes.service';
import { WardsService } from '../../../dapfood/services/wards.service';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../../shared/utilities';
import { CustomerTypeOption, UserType } from '../../../dapfood/common/constant';
import { UsersTempService } from '../../../dapfood/services/userstemp.service';

@Component({
  selector: 'app-user-information-tdv-edit',
  templateUrl: './user-information-tdv-edit.component.html',
  styleUrls: ['./user-information-tdv-edit.component.scss']
})
export class UserInformationTdvEditComponent extends SecondPageEditBase implements OnInit {
  formGroup: FormGroup;
  formGroupTdv: FormGroup;
  isShow = false;
  customerTypes_options: any = [];
  district_options: any = [];
  userGroups_options: any = [];
  pharmacyType_options: any = [];
  tdvGroups_options = [];
  ward_options: any = [];
  district_options_temp: any = [];
  ward_options_temp: any = [];
  placeOfIssueDkdn_options: any = [];
  province_options: any = [];
  isLoading = false;
  submitted = false;
  modelEdit: any = {
    userId: 0,
    name: "",
    idType: -1
  };

  isFormConfirmEdit: boolean = false;
  modelEditByTdv: any = {};

  dataDifference: any = {};

  isCreate = false;
  UserType = UserType;
  accountType_options = [];
  isShowAccountType = false;

  @Input() isFormTdv = false;
  @Input() titleHeader: string = "Thêm/Sửa nhà thuốc";

  constructor(
    protected _injector: Injector,
    private _ProvincesService: ProvincesService,
    private _DistrictsService: DistrictsService,
    private _customerTypesService: CustomerTypesService,
    private _WardsService: WardsService,
    private _UserService: UserService,
    private _UsersTempService: UsersTempService,
    private _configurationService: ConfigurationService,

  ) {
    super(null, _injector);
  }

  async ngOnInit() {
    this.init();
  }

  async init() {
    this.vi = this._configurationService.calendarVietnamese;
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      password: new FormControl(''),  //, Validators.compose([Validators.required, Validators.minLength(6)])
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      code: new FormControl(''),
      avatar: new FormControl(''),
      legalRepresentatives: new FormControl(''),
      pharmacistPtcm: new FormControl(''),
      idCardPtcm: new FormControl(''),
      phonePtcm: new FormControl(''),
      idProvince: new FormControl(''),
      idDistrict: new FormControl(''),
      idWard: new FormControl(''),
      address: new FormControl(''),
      certificateNumberDkdn: new FormControl(''),
      certificateDkdnDate: new FormControl(''),
      placeOfIssueDkdn: new FormControl(''),
      certificateDkdn: new FormControl(''),
      certificateNumberDdkkd: new FormControl(''),
      certificateDdkkdDate: new FormControl(''),
      placeOfIssueDdkkd: new FormControl(''),
      certificateDdkkd: new FormControl(''),
      certificateNumberGdpGppDsp: new FormControl(''),
      certificateGdpGppDspDate: new FormControl(''),
      placeOfIssueGdpGppDsp: new FormControl(''),
      certificateGdpGppDsp: new FormControl(''),
      idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
      userCustomerTypes: new FormControl(''),
      userName: new FormControl(''),
      passwordRepeat: new FormControl(''),
      idCustomerType: new FormControl(''),
      idGroup: new FormControl(''),
      idType: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
    });

    this.formGroupTdv = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      password: new FormControl(''),  //, Validators.compose([Validators.required, Validators.minLength(6)])
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      code: new FormControl(''),
      avatar: new FormControl(''),
      legalRepresentatives: new FormControl(''),
      pharmacistPtcm: new FormControl(''),
      idCardPtcm: new FormControl(''),
      phonePtcm: new FormControl(''),
      idProvince: new FormControl(''),
      idDistrict: new FormControl(''),
      idWard: new FormControl(''),
      address: new FormControl(''),
      certificateNumberDkdn: new FormControl(''),
      certificateDkdnDate: new FormControl(''),
      placeOfIssueDkdn: new FormControl(''),
      certificateDkdn: new FormControl(''),
      certificateNumberDdkkd: new FormControl(''),
      certificateDdkkdDate: new FormControl(''),
      placeOfIssueDdkkd: new FormControl(''),
      certificateDdkkd: new FormControl(''),
      certificateNumberGdpGppDsp: new FormControl(''),
      certificateGdpGppDspDate: new FormControl(''),
      placeOfIssueGdpGppDsp: new FormControl(''),
      certificateGdpGppDsp: new FormControl(''),
      idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
      userCustomerTypes: new FormControl(''),
      userName: new FormControl(''),
      passwordRepeat: new FormControl(''),
      idCustomerType: new FormControl(''),
      idGroup: new FormControl(''),
      idType: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
    });

    await this.loadUserTypes(); // Loại khách hàng
    await this.loadPharmacyTypes();

    this.accountType_options = [
      { label: 'Loại tài khoản', value: -1 },
      { label: 'Nhà thuốc', value: UserType.NhaThuoc },
      { label: 'Trình dược viên', value: UserType.TrinhDuocVien },
      { label: 'Doanh nghiệp', value: UserType.DoanhNghiep },
      { label: 'Kho', value: UserType.Kho },
    ]
  }

  async showPopup(data) {
    this.isFormConfirmEdit = data.isFormConfirmEdit;
    this.modelEdit = {};
    this.modelEditByTdv = {};
    this.isShow = true;
    this.submitted = false;
    this.modelEdit.idType = data.idType;
    this.resetAllCheckboxes();
    this.isShowAccountType = data.isShowAccountType;
    this.dataDifference = {};
    await this.onLoadProvinces();

    if (data.id > 0) {

      this.formGroup.get('password').clearValidators();
      this.formGroup.get('password').updateValueAndValidity();

      // await this._UserService.GetByUserId(data.id).then(async rs => {
      //   if (rs.status) {
      //     this.modelEdit = rs.data;
      //     if (this.modelEdit.certificateDkdnDate) {
      //       this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
      //     }
      //     if (this.modelEdit.certificateGdpGppDspDate) {
      //       this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
      //     }
      //     if (this.modelEdit.certificateDdkkdDate) {
      //       this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
      //     }
      //   }
      // }, () => {
      //   this._notifierService.showHttpUnknowError();
      // });
      //  await this._UserService.GetUserTempById(data.id).then(async rs => {
      //   if (rs.status) {
      //     this.modelEditByTdv = rs.data;
      //     if (this.modelEditByTdv.certificateDkdnDate) {
      //       this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
      //     }
      //     if (this.modelEditByTdv.certificateGdpGppDspDate) {
      //       this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
      //     }
      //     if (this.modelEditByTdv.certificateDdkkdDate) {
      //       this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
      //     }
      //   }
      // }
      // , () => {
      //   this._notifierService.showHttpUnknowError();
      // });
      await this._UsersTempService.CompareTwoTableUserAndUserTemp(data.id).then(async rs => {
        if (rs.status) {
          this.modelEdit = rs.data.user;
          this.modelEditByTdv = rs.data.userTemp;
          this.dataDifference = rs.data.differences;
          if (this.modelEdit.certificateDkdnDate) {
            this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
          }
          if (this.modelEdit.certificateGdpGppDspDate) {
            this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
          }
          if (this.modelEdit.certificateDdkkdDate) {
            this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
          }

          if (this.modelEditByTdv.certificateDkdnDate) {
            this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEditByTdv.certificateDkdnDate);
          }
          if (this.modelEditByTdv.certificateGdpGppDspDate) {
            this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEditByTdv.certificateGdpGppDspDate);
          }
          if (this.modelEditByTdv.certificateDdkkdDate) {
            this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEditByTdv.certificateDdkkdDate);
          }

        }
      }, () => {
        this._notifierService.showHttpUnknowError();
      });

      // if (data.tdvEditTemp != null && data.tdvEditTemp > 0) {
      //   this.formGroup.addControl('isEditTdvTemp ', new FormControl(data.tdvEditTemp));
      //   this.modelEdit.isEditTdvTemp = data.tdvEditTemp
      // }

    } else {
      this.formGroup.get('password').setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.get('password').updateValueAndValidity();
      this.modelEdit = {
        userId: 0,
        idType: data.idType
      };
      this.isCreate = true;
    }

    if (this.modelEdit.idType === UserType.NhaThuoc) {
      this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
    } else {
      this.formGroup.get('idPharmacyType').clearValidators();
    }
    this.formGroup.get('idPharmacyType').updateValueAndValidity();
    if (data.idType === -1 && data.id <= 0) {
      this.formGroup.get('accountType').setValidators([Validators.required]);
      this.formGroup.get('accountType').updateValueAndValidity();
    }

    if (this.modelEdit.idType === UserType.NhaThuoc)
      await this.loadUserGroupsNT();
    this.onLoadDistricts();
    this.onLoadWards();
    this.onLoadDistrictsTemp();
    this.onLoadWardsTemp();
  }

  // load bản cập nhật do tdv update

  //loại khách hàng
  async loadUserTypes() {
    this.customerTypes_options = [];
    await this._customerTypesService.GetShort('', CustomerTypeOption.IdCustomerType, 0, 10000).then(rs => {
      if (rs.status) {
        let dataArr: any = [];
        rs.data.forEach(item => {
          dataArr.push({ label: item.name, value: item.id });
        });
        this.customerTypes_options = dataArr;
      }
    });
  }

  //loại hình nhà thuốc
  async loadPharmacyTypes() {
    this.pharmacyType_options = [];
    await this._customerTypesService.GetShort('', CustomerTypeOption.idPharmacyType, 0, 10000).then(rs => {
      if (rs.status) {
        rs.data.forEach(item => {
          this.pharmacyType_options.push({ label: item.name, value: item.id });
        });
      }
    });
  }

  async onLoadDistricts() {
    await this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
      if (rs.status) {
        this.district_options = rs.data;
      }
    });
  }

  async onLoadProvinces() {
    await this._ProvincesService.GetShort().then(rs => {
      if (rs.status) {
        this.province_options = rs.data;
        this.placeOfIssueDkdn_options = rs.data;
      }
    });
  }

  async onLoadWards() {
    await this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
      if (rs.status) {
        this.ward_options = rs.data;
      }
    });
  }

  async onLoadDistrictsTemp() {
    await this._DistrictsService.GetShort(this.modelEditByTdv.idProvince).then(rs => {
      if (rs.status) {
        this.district_options_temp = rs.data;
      }
    });
  }

  async onLoadWardsTemp() {
    await this._WardsService.GetShort(this.modelEditByTdv.idDistrict).then(rs => {
      if (rs.status) {
        this.ward_options_temp = rs.data;
      }
    });
  }

  //nhóm khách hàng
  async loadUserGroupsNT() {
    await this._customerTypesService.GetShort('', CustomerTypeOption.IdGroupNT, 0, 10000).then(rs => {
      if (rs.status) {
        var list: any = [];
        rs.data.forEach(item => {
          list.push({ label: item.name, value: item.id });
        });
        this.userGroups_options = list;
      }
    });
  }

  save() {
    this.isLoading = true;
    this.submitted = true;
    if (this.formGroup.valid) {
      this.modelEdit.username = this.modelEdit.phone; // this.f.userName.setValue(this.f.phone.value);
      this.modelEdit.passwordRepeat = this.modelEdit.password; //this.f.passwordRepeat.setValue(this.f.password.value);
      let _userCustomerTypes = [];

      this.modelEdit.userCustomerTypes = _userCustomerTypes; //this.f.userCustomerTypes.setValue(_userCustomerTypes);
      if (this.isFormConfirmEdit) {
        this.formGroup.addControl('confirmUpdate', new FormControl('', Validators.required));
        this.modelEdit.confirmUpdate = true;
      }
      this._UserService.post(this.modelEdit).then(async (response) => {
        if (response.status) {
          if (response.data != null && response.data.userId > 0) {
            this.modelEdit.userId = response.data.userId;
            this.modelEdit.isDeleted = 0;

            this._notifierService.showSuccess(response.message);
            this.closePopupMethod(response.data);
          }
        } else {
          this.isLoading = false;
          this._notifierService.showWarning('Cập nhật thông tin người dùng thất bại.\n' + response.message);
        }
      })
        .catch(error => {
          this.isLoading = false;
          this._notifierService.showWarning(Utilities.getErrorDescription(error));
        });
    }
    else {
      const invalid = this.findInvalidControls();
      this._notifierService.showError(invalid, 'Vui lòng nhập đủ thông tin');
    }

    this.isLoading = false;
  }

  findInvalidControls() {
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        const ce = controls[name].errors;
        // if (ce.required) return `Vui lòng nhập ${this.getControlTitle(name)}`;
        if (ce.minlength) return `${this.getControlTitle(name)} ít nhất ${ce.minlength.requiredLength} ký tự`;
        if (ce.email) return `${this.getControlTitle(name)} sai định dạng`;
      }
    }
    return '';
  }

  closePopupMethod(data: any) {
    this.formGroup.reset();
    this.isShow = false;
    this.closePopup.next(data);
  }

  getControlTitle(name) {
    switch (name) {
      case 'name':
        return 'Tên nhà thuốc/phòng khám/TDV';
      case 'phone':
        return 'Số điện thoại';
      case 'password':
        return 'Mật khẩu';
      case 'email':
        return 'Email';
      case 'idPharmacyType':
        return 'Loại tài khoản';
    }
  }

  accountTypeChange() {
    if (this.modelEdit.idType === UserType.NhaThuoc) {
      this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
      this.loadUserGroupsNT();
    } else {
      this.formGroup.get('idPharmacyType').clearValidators();
    }
    this.formGroup.get('idPharmacyType').updateValueAndValidity();
  }

  onCheckboxChange(event: Event) {
    var checkbox = event.target as HTMLInputElement;
    var controlName = checkbox.getAttribute('data-control-name');
    if (checkbox.checked && controlName) {
      if (controlName === 'location') {
        this.modelEdit.idProvince = this.modelEditByTdv.idProvince;
        this.modelEdit.idDistrict = this.modelEditByTdv.idDistrict;
        this.modelEdit.idWard = this.modelEditByTdv.idWard;
        this.onLoadDistricts();
        this.onLoadWards();
      } else if (controlName === 'locateLatLng') {
        this.modelEdit.lat = this.modelEditByTdv.lat;
        this.modelEdit.lng = this.modelEditByTdv.lng;
      } else {
        this.modelEdit[controlName] = this.modelEditByTdv[controlName];
      }
    }
  }

  resetAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"][data-control-name]') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
  }
}
