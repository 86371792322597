import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
@Component({
    selector: 'file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileViewerComponent extends ComponentBase implements OnInit {

    showFileDialog = false;
    selectedIndex: number = 0;

    listFile: any[] = [];
    file: any = {
        filenName: '',
        // path: '',
        type: 1,
        url: ''
    };

    constructor(
    ) {
        super();
    }

    ngOnInit() {
    }

    // Sử dụng với file list idFile
    showPopup(idFile: string, data: any[] = []) {
        this.listFile = [];

        this.file.type = 1;
        this.file.url = this.getImageOriginal(idFile);

        if (data.length > 0) {
            data.forEach(item => {
                this.listFile.push({ id: item, url: this.getImageOriginal(item), type: 1 });
            });

            this.file = this.listFile.find(s => s.id == idFile);    // Gán lại file trong list để hàm next tìm được
        }

        // const fileType = this.getFileType(this.FileName);
        // const documentType = this.getDocumentType(fileType);
        // if (documentType == 'image') {
        // this.isImage = true;
        // }
        // else {
        //     this.isImage = false;
        //     this.initDocumentViewer(fileType, documentType);
        // }
        this.showFileDialog = true;
    }

    // Sử dụng với List Object File
    showPopupObject(file: any, data: any[] = []) {

        if (data.length > 0) {
            this.listFile = data.filter(x => x.type == 1);  // Chỉ view file image và video
        }

        this.file = file;
        if (this.file.type == 3)
            this.file.url = `${environment.apiDomain.fileEndpoint}${this.file.path}`;
        else
            this.file.url = this.urlImageOriginal(this.file.path);

        this.showFileDialog = true;
    }

    getCurrent(item: any, index: number) {
        const currentIndex = this.listFile.indexOf(item);
        this.selectedIndex = currentIndex;
        this.file = this.listFile[currentIndex];
        this.file.url = item.path ? this.urlImageOriginal(this.file.path) : this.file.url;
    }

    onNext() {
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0 ) return;
        const currentIndex = this.listFile.indexOf(this.file);

        const nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = nextIndex;
    }

    onPrev() {
        if (this.file == null || this.file.path == "" || this.listFile == null || this.listFile.length <= 0 ) return;
        const currentIndex = this.listFile.indexOf(this.file);
        let prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;

        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.file.path ? this.urlImageOriginal(this.file.path) : this.file.url;
        this.selectedIndex = prevIndex;
    }

    @HostListener('window:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        // event.preventDefault();
        if (event.key === 'ArrowLeft') {
        //if (event.keyCode == 37) {
            this.onPrev();
        }
        if (event.key === 'ArrowRight') {
            this.onNext();
        }
    }

    // urlFile(file) {
    //     if (file.path == null || file.path == undefined)
    //         return `/assets/images/no_image.jpg`; 
    //     return `https://docs.google.com/viewerng/viewer?url=https://wmapi.friends.vn/file.api//2023/9/23/mickey-tai-lieu-api_20230923115008708.docx`;
    // }
}
